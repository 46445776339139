const StatisticsData9=[
    {
        id: 1,
        xAxis: "AK",
        yAxis: "5.3",
        number: "95"
    },
    {
        id: 2,
        xAxis: "AL",
        yAxis: "5.8",
        number: "283"
    },
    {
        id: 3,
        xAxis: "AR",
        yAxis: "4.4",
        number: "142"
    },
    {
        id: 4,
        xAxis: "AZ",
        yAxis: "0.7",
        number: "286"
    },
    {
        id: 5,
        xAxis: "CA",
        yAxis: "6.0",
        number: "1978"
    },
    {
        id: 6,
        xAxis: "CO",
        yAxis: "4.7",
        number: "782"
    },
    {
        id: 7,
        xAxis: "CT",
        yAxis: "3.0",
        number: "294"
    },
    {
        id: 8,
        xAxis: "DC",
        yAxis: "1.7",
        number: "6"
    },
    {
        id: 9,
        xAxis: "DE",
        yAxis: "5.3",
        number: "191"
    },
    {
        id: 10,
        xAxis: "FL",
        yAxis: "3.7",
        number: "465"
    },
    {
        id: 11,
        xAxis: "GA",
        yAxis: "3.0",
        number: "220"
    },
    {
        id: 12,
        xAxis: "HI",
        yAxis: "2.9",
        number: "9"
    },
    {
        id: 13,
        xAxis: "IA",
        yAxis: "4.7",
        number: "348"
    },
    {
        id: 14,
        xAxis: "ID*",
        yAxis: "0",
        number: "0"
    },
    {
        id: 15,
        xAxis: "IL",
        yAxis: "3.6",
        number: "314"
    },
    {
        id: 16,
        xAxis: "IN",
        yAxis: "5.2",
        number: "435"
    },
    {
        id: 17,
        xAxis: "KS",
        yAxis: "6.0",
        number: "173"
    },
    {
        id: 18,
        xAxis: "KY",
        yAxis: "2.7",
        number: "157"
    },
    {
        id: 19,
        xAxis: "LA",
        yAxis: "2.7",
        number: "96"
    },
    {
        id: 20,
        xAxis: "MA",
        yAxis: "3.5",
        number: "476"
    },
    {
        id: 21,
        xAxis: "MD*",
        yAxis: "0",
        number: "0"
    },
    {
        id: 22,
        xAxis: "ME",
        yAxis: "4.4",
        number: "84"
    },
    {
        id: 23,
        xAxis: "MI",
        yAxis: "4.5",
        number: "788"
    },
    {
        id: 24,
        xAxis: "MN",
        yAxis: "4.7",
        number: "645"
    },
    {
        id: 25,
        xAxis: "MO",
        yAxis: "6.2",
        number: "521"
    },
    {
        id: 26,
        xAxis: "MS",
        yAxis: "4.5",
        number: "82"
    },
    {
        id: 27,
        xAxis: "MT",
        yAxis: "1.8",
        number: "7"
    },
    {
        id: 28,
        xAxis: "NC",
        yAxis: "0.9",
        number: "100"
    },
    {
        id: 29,
        xAxis: "ND",
        yAxis: "1.5",
        number: "8"
    },
    {
        id: 30,
        xAxis: "NE",
        yAxis: "2.5",
        number: "61"
    },
    {
        id: 31,
        xAxis: "NH",
        yAxis: "7.2",
        number: "39"
    },
    {
        id: 32,
        xAxis: "NJ",
        yAxis: "3.6",
        number: "672"
    },
    {
        id: 33,
        xAxis: "NM*",
        yAxis: "0",
        number: "0"
    },
    {
        id: 34,
        xAxis: "NV",
        yAxis: "7.4",
        number: "168"
    },
    {
        id: 35,
        xAxis: "NY",
        yAxis: "4.1",
        number: "1282"
    },
    {
        id: 36,
        xAxis: "OH",
        yAxis: "3.4",
        number: "247"
    },
    {
        id: 37,
        xAxis: "OK",
        yAxis: "3.8",
        number: "200"
    },
    {
        id: 38,
        xAxis: "OR*",
        yAxis: "0",
        number: "0"
    },
    {
        id: 39,
        xAxis: "PA",
        yAxis: "3.3",
        number: "153"
    },
    {
        id: 40,
        xAxis: "PR",
        yAxis: "5.8",
        number: "5"
    },
    {
        id: 41,
        xAxis: "RI",
        yAxis: "2.9",
        number: "86"
    },
    {
        id: 42,
        xAxis: "SC",
        yAxis: "6.3",
        number: "449"
    },
    {
        id: 43,
        xAxis: "SD",
        yAxis: "3.6",
        number: "132"
    },
    {
        id: 44,
        xAxis: "TN",
        yAxis: "5.4",
        number: "249"
    },
    {
        id: 45,
        xAxis: "TX",
        yAxis: "4.3",
        number: "499"
    },
    {
        id: 46,
        xAxis: "UT",
        yAxis: "5.9",
        number: "200"
    },
    {
        id: 47,
        xAxis: "VA",
        yAxis: "5.5",
        number: "305"
    },
    {
        id: 48,
        xAxis: "VT",
        yAxis: "5.5",
        number: "79"
    },
    {
        id: 49,
        xAxis: "WA*",
        yAxis: "0",
        number: "0"
    },
    {
        id: 50,
        xAxis: "WI",
        yAxis: "4.1",
        number: "192"
    },
    {
        id: 51,
        xAxis: "WV",
        yAxis: "6.5",
        number: "18"
    },
    {
        id: 52,
        xAxis: "WY",
        yAxis: "4.8",
        number: "52"
    }
]
export default StatisticsData9;