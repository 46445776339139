import React from 'react';
import {BarChart} from "components/ui";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData9 from "data/data-9-treatment-admissions-pregnant.js"
import StatisticsEntry from 'templates/statistics-detail-page-layout';

const isBrowser = typeof window !== "undefined"

const StatisticsPage9=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 8)
        const interactiveStatisticsPageData = pageData[0];
       
    return (
        <StatisticsEntry
        location={location}
        title={interactiveStatisticsPageData.title}
        description={interactiveStatisticsPageData.metaDescription}
        author={interactiveStatisticsPageData.author}
        year={interactiveStatisticsPageData.year}
        note={<span>*2020 TEDS-A Data was not available for Idaho, Maryland, New Mexico, Oregon, and Washington.
            <br />
            **2020 Estimates may be influenced by the COVID-19 pandemic. </span>}
        chartNotes={<><p>Number of Admissions for Women (15-44) who Entered Treatment: 376,719</p>
        <p>Number of Admissions for Women Pregnant at Entry: 14,073 (3.9%)</p></>}
        fileName={interactiveStatisticsPageData.fileName}
        fileSize={interactiveStatisticsPageData.fileSize}>
            {/* vertical chart */}
            <BarChart
            title={interactiveStatisticsPageData.title}
            chartLabels={[...StatisticsData9.map(data=>data.xAxis)]}
            chartData={[...StatisticsData9.map(data=>data.yAxis)]}
            xAxisLabel="State"
            yAxisLabel="Percentage"
            chartLayoutVertical={true}
            yAxisIncrement={1}
            yAxisMax={8}
            isPercent={true}
            xAxisRotation={90}
            xAxisFontSize={100}
            />     
            {/* horizontal chart */}
            {/* <BarChart
            title={interactiveStatisticsPageData.title}
            chartLabels={[...statistics8Data.map(data=>data.xAxis)]}
            chartData={[...statistics8Data.map(data=>data.yAxis)]}
            xAxisLabel="Percentage"
            yAxisLabel="State"
            chartLayoutVertical={false}
            yAxisIncrement={1}
            yAxisMax={8}
            isPercent={true}
            xAxisFontSize={100}
            />  */}
            <p>
                Nationally, 3.9% (N=14,073) of treatment admissions were for women who were pregnant, this percentage varies greatly by state.
            </p>
        </StatisticsEntry>
    )
}
export default StatisticsPage9;